import React from "react";
import moment from "moment";

import { ChartHeaderProps } from "./types";
import { numberFormatter } from "application/helpers/numberFormatter";

import {
  IndexName,
  InfoBlock,
  MetricName,
  LastPointSD,
  LastPointDate,
  LastPointValue,
  HeaderContainer,
  PeriodContainer,
  LastPointCircle,
  LastPointPercent,
  LastPointContainer,
  MobileLastPointCircle,
} from "./styles";
import { ChartText } from "features/chart/styles";
import calendarIconSource from "application/assets/images/icons/calendar-icon.svg";
import { SpecificMetricCodes } from "features/chart/constants";

const ChartHeader = ({ data, filters, isPercent }: ChartHeaderProps) => {
  const firstPoint = data[0];
  const lastPoint = data[data.length - 1];
  const firstPointDate = moment(firstPoint?.a).format("MMM D, YYYY");
  const lastPointDate = moment(lastPoint?.a).format("MMM D, YYYY");
  const difference = SpecificMetricCodes.includes(filters.selectedMetric.code);

  const getValue = () => {
    if (isPercent) {
      return `${numberFormatter(lastPoint?.b)}%`;
    } else {
      return numberFormatter(lastPoint?.b);
    }
  };

  return (
    <HeaderContainer>
      <div>
        <IndexName>{filters.selectedIndices.name ?? ""}</IndexName>
        <MetricName>{filters.selectedMetric.name ?? ""}</MetricName>
      </div>

      <div>
        <PeriodContainer>
          <img src={calendarIconSource} alt="calendar" />
          <ChartText>
            All periods ({firstPointDate} - {lastPointDate})
          </ChartText>
        </PeriodContainer>

        <LastPointContainer>
          <InfoBlock>
            <LastPointDate>As of {lastPointDate}</LastPointDate>
            <LastPointCircle />
          </InfoBlock>

          <div style={{ display: "flex" }}>
            <InfoBlock>
              <LastPointValue>Current value</LastPointValue>
              <LastPointPercent>{getValue()}</LastPointPercent>
            </InfoBlock>

            {lastPoint && !!lastPoint.sd && (
              <InfoBlock>
                <LastPointCircle />
                <MobileLastPointCircle />
                <LastPointValue>
                  {difference ? "Difference" : "Standard deviations"}
                </LastPointValue>
                <LastPointSD>{numberFormatter(lastPoint?.sd)}</LastPointSD>
              </InfoBlock>
            )}
          </div>
        </LastPointContainer>
      </div>
    </HeaderContainer>
  );
};

export default ChartHeader;
