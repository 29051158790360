import {
  ChartWidth,
  ChartHeight,
  ToolTipWidth,
  ToolTipHeight,
  ToolTipHeightWithMovingAvg,
} from "../constants";

export function getTooltipPosition(x: number, y: number, hasMovingAvg: boolean) {
  const ShadowValue = 5;
  const xMargin = 7;
  const yMargin = 5;
  let tooltipX = x + xMargin;
  let tooltipY = y - yMargin;

  const sumX = x + ToolTipWidth + ShadowValue;
  if (sumX > ChartWidth) {
    tooltipX = x - ToolTipWidth - ShadowValue - xMargin / 2;
  }

  const finalToolTipHeight = hasMovingAvg
    ? ToolTipHeightWithMovingAvg
    : ToolTipHeight;

  const sumY = y + finalToolTipHeight + ShadowValue;
  if (sumY > ChartHeight) {
    tooltipY = y - finalToolTipHeight - ShadowValue + yMargin * 2;
  }

  return { tooltipX, tooltipY };
}
