import React, {useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import AuthActions from "../../store/actions";
import { PathNames } from "application/routes";
import { RegistrationSchema } from "../validations";
import { authSelector } from "common/auth/store/slice";
import { CreateUserParams } from "integration/api/ApiAuth/types";
import { useAppDispatch, useAppSelector } from "application/store";
import { requestSelector } from "common/responseHandlerService/slice";
import { applicationConfig } from "application/config/applicationConfig";

import SideBar from "features/side-bar";
import Checkbox from "application/components/CheckBox";
import PrimaryInput from "application/components/PrimaryInput";
import PrimaryButton from "application/components/PrimaryButton";

import PopUp from "../../../../application/components/PopUp";

import {
  SignUpTitle,
  PasswordTip,
  SignUpContent,
  SignUpSubTitle,
  AgreeContainer,
  RegistrationBtn,
} from "../styles";
import {
  AuthText,
  AuthWrapper,
  AuthContainer,
  AuthActionText,
  AuthInputWrapper,
  AuthActionNormalText,
  BottomActionContainer,
  AuthDoubleInputWrapper,
  MobileConfirmButton,
} from "../../styles";
import MobileFooter from "../../../../application/components/MobileFooter";
import MobileCopyrightBlock from "../../../../application/components/MobileCopyright";
import {useMediaQuery, useTheme} from "@mui/material";

const Registration = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showPopup, setShowPopup] = useState(false);

  const { signUpUserInfo } = useAppSelector(authSelector);
  const { loading, error } = useAppSelector(requestSelector).createUser;

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1023));
  const isMobile = useMediaQuery(theme.breakpoints.down(674));

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({top: 0})
    }, 300);
  }, []);

  const {
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateUserParams>({
    resolver: yupResolver(RegistrationSchema),
    defaultValues: {
      ...signUpUserInfo.registrationStep,
    },
  });
  const values = watch();

  const handlePrivacy = () => {
    window.open(applicationConfig.privacy_policy, "_blank");
  };

  const toggleAgreeMarketing = () => {
    setValue("isAgreeToMarketingOffers", !values.isAgreeToMarketingOffers);
  };

  const onSubmit = (data: CreateUserParams) => {
    dispatch(AuthActions.createUser(data)).then((res) => {
      if (res.type.endsWith("/fulfilled")) {
        navigate(PathNames.registrationBilling);
      }
    });
  };

  const handleShowPopup = () => {
    setShowPopup(!showPopup);
  }

  const PasswordRules = () => {
    return (
      <PasswordTip>
        <li>
          <span>May contain letters, numbers, symbols</span>
        </li>
        <li>
          <span>At least 10 characters long</span>
        </li>
        <li>
          <span>Contain upper and lowercase letters</span>
        </li>
      </PasswordTip>
    );
  };

  return (
    <AuthWrapper>
      <SideBar type="sign-up" activeStep={1} />
      <AuthContainer isSignUp>
        <SignUpContent>
          <SignUpTitle>Account Info</SignUpTitle>
          <SignUpSubTitle>
            We use the following information to create your account. We work
            hard to keep your info private and protected. Please see our
            <AuthActionText onClick={handlePrivacy}>
              {" "}
              privacy policy
            </AuthActionText>{" "}
            for more information on how we keep your data safe.
          </SignUpSubTitle>

          <AuthInputWrapper>
            <PrimaryInput
              required
              id="email"
              register={register}
              label="Email Address"
              placeholder="Email Address"
              error={errors.email?.message || error}
            />
          </AuthInputWrapper>

          <AuthDoubleInputWrapper>
            <PrimaryInput
              required
              id="firstName"
              register={register}
              label="First Name"
              placeholder="First Name"
              error={errors.firstName?.message}
            />
            <PrimaryInput
              required
              id="lastName"
              register={register}
              label="Last Name"
              placeholder="Last Name"
              error={errors.lastName?.message}
            />
          </AuthDoubleInputWrapper>

          <PrimaryInput
            required
            id="password"
            type="password"
            register={register}
            label="Password"
            placeholder="Password"
            tip={isMobile ? false : <PasswordRules />}
            error={isMobile ? errors.password?.message : errors.password?.message && <PasswordRules />}
            onMobileClick={handleShowPopup}
            isSignUp={true}
          />

          {!isMobile && (
            <AgreeContainer>
              <Checkbox
                onClick={toggleAgreeMarketing}
                checked={values.isAgreeToMarketingOffers}
              />
              <AuthText>
                I want to receive Asset Camp commentary, updates, and education via email.
              </AuthText>
            </AgreeContainer>
          )}

          {!isMobile && (
            <RegistrationBtn>
              <PrimaryButton
                title="Continue"
                loading={loading}
                onClick={handleSubmit(onSubmit)}
              />
            </RegistrationBtn>
          )}

          <BottomActionContainer>
            <AuthActionNormalText>Have an account?</AuthActionNormalText>
            <AuthActionText onClick={() => navigate(PathNames.login)}>
              Login
            </AuthActionText>
          </BottomActionContainer>
        </SignUpContent>

        {isTablet && (
          <MobileFooter>
            {isMobile && (
              <AgreeContainer>
                <Checkbox
                  onClick={toggleAgreeMarketing}
                  checked={values.isAgreeToMarketingOffers}
                />
                <AuthText>
                  I want to receive Asset Camp commentary, updates, and education via email.
                </AuthText>
              </AgreeContainer>
            )}
            {isMobile && (
              <MobileConfirmButton>
                <PrimaryButton
                  title="Continue"
                  loading={loading}
                  onClick={handleSubmit(onSubmit)}
                />
              </MobileConfirmButton>
            )}
            <MobileCopyrightBlock />
          </MobileFooter>
        )}
        {showPopup && <PopUp onClose={handleShowPopup} />}
      </AuthContainer>
    </AuthWrapper>
  );
};

export default Registration;
