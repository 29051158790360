import React, { useCallback, useEffect } from "react";

import StockValuationActions from "./store/actions";
import { FilterData, FilterDataItem } from "./store/model";
import { useAppDispatch, useAppSelector } from "application/store";
import { requestSelector } from "common/responseHandlerService/slice";
import {
  homeSelector,
  setFilterIndices,
  setFilterMetric,
  resetState,
} from "./store/slice";

import Chart from "features/chart";
import DropGroup from "application/components/DropGroup";

import {
  Title,
  DropContainer,
  FiltersContainer,
  GoToSummaryTableButton,
  GoToSummaryTableIcon,
} from "./styles";
import MainContainer from "../../application/components/MainContainer";
import { useMediaQuery, useTheme } from "@mui/material";
import { PathNames } from "application/routes";

const GoToSummaryTable = ({ onClick }: { onClick: () => void }) => {
  return (
    <GoToSummaryTableButton onClick={onClick}>
      <GoToSummaryTableIcon />
      <p>Go to summary table</p>
    </GoToSummaryTableButton>
  );
};

const ValuationStock = () => {
  const dispatch = useAppDispatch();
  const requestData = useAppSelector(requestSelector);
  const { metrics, indices, indicesData, filters } =
    useAppSelector(homeSelector);
  const loading =
    requestData.getIndices.loading ||
    requestData.getIndicesData.loading ||
    requestData.getMetrics.loading;

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1024));

  useEffect(() => {
    if (!indicesData.data.length) {
      Promise.all([
        dispatch(StockValuationActions.getMetrics()),
        dispatch(StockValuationActions.getIndices()),
      ]).then((resArr) => {
        const metricRes = resArr[0].payload as FilterData;
        const indicesRes = resArr[1].payload as FilterData;
        const metricKeys = Object.keys(metricRes);
        const indicesKeys = Object.keys(indicesRes);

        const firstIndices = indicesRes[indicesKeys[0]][0];
        const firstMetric = metricRes[metricKeys[0]][0];
        handleIndicesSelect(firstIndices);
        handleMetricSelect(firstMetric);
      });
    }
    return () => {
      dispatch(resetState());
    };
  }, []);

  useEffect(() => {
    if (filters.selectedIndices.code && filters.selectedMetric.code) {
      dispatch(StockValuationActions.getIndicesData());
    }
  }, [filters]);

  const handleIndicesSelect = (item: FilterDataItem) => {
    dispatch(setFilterIndices(item));
  };

  const handleMetricSelect = (item: FilterDataItem) => {
    dispatch(setFilterMetric(item));
  };

  const handleGoToSummaryTable = useCallback(() => {
    window.open(
      `${window.origin}${PathNames.valuationSummary}?metric=${filters.selectedMetric.code}`,
      "_blank"
    );
  }, [filters]);

  return (
    <MainContainer>
      <FiltersContainer>
        <Title>Valuations, Earnings, and Trends Charts</Title>
        <DropContainer>
          <DropGroup
            data={indices}
            label="Index"
            disabled={loading}
            onSelect={handleIndicesSelect}
            selected={filters.selectedIndices}
            placeholder="Index name goes here"
          />
          <DropGroup
            data={metrics}
            label="Metric"
            disabled={loading}
            onSelect={handleMetricSelect}
            selected={filters.selectedMetric}
            placeholder="Metric name goes here"
          />
          <GoToSummaryTable onClick={handleGoToSummaryTable} />
        </DropContainer>
      </FiltersContainer>

      <Chart
        loading={loading}
        indicesData={indicesData}
        filters={filters}
        isTablet={isTablet}
      />
    </MainContainer>
  );
};

export default ValuationStock;
