import React, { useEffect } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "application/store";
import { applicationConfig } from "application/config/applicationConfig";
import MSCIInfo from "application/components/MSCIInfo";
import SmallLoader from "application/components/SmallLoader";
import MainContainer from "application/components/MainContainer";
import NotifyPopUpTable from "application/components/NotifyPopUpTable";
import IndicesPerformanceSummaryFilters from "application/components/ReturnsSummaryFilters";
import {
  Title,
  Loader,
  Container,
  BottomLogo,
  TitleContainer,
  UnderContentInfo,
  LogoWrapper,
  BottomMfruLogo,
} from "./styles";
import logoBlueSource from "application/assets/images/logo-blue.svg";
import mfruLogoSource from "application/assets/images/mfru-logo.svg";
import { indicesPerformanceSummarySelector, resetState } from "./store/slice";
import { requestSelector } from "common/responseHandlerService/slice";
import ReturnsSummaryTable from "application/components/ReturnsSummaryTable";
import {
  Data,
  NoData,
} from "application/components/ReturnsSummaryTable/types";
import { find, propEq } from "ramda";

const IndicesSummary = () => {
  const dispatch = useAppDispatch();
  const requestData = useAppSelector(requestSelector);
  const loading =
    requestData.getIndicesPerformanceSummary.loading ||
    requestData.getMetrics.loading;

  const {
    indicesSummaryData: { data },
  } = useAppSelector(indicesPerformanceSummarySelector);

  const buttonSectionRef = React.useRef<HTMLDivElement>(null);
  const [buttonSectionHeight, setButtonSectionHeight] =
    React.useState<number>(0);

  useEffect(() => {
    //Clear state on component unmount
    return () => {
      dispatch(resetState());
    };
  }, []);

  const theme = useTheme();
  const isWidthLessFirstMediaStep = useMediaQuery(theme.breakpoints.down(1024));

  useEffect(() => {
    if (buttonSectionRef.current) {
      if (isWidthLessFirstMediaStep) {
        // need for sticky table header adjustment
        setButtonSectionHeight(buttonSectionRef.current.clientHeight + 32);
      } else {
        setButtonSectionHeight(buttonSectionRef.current.clientHeight);
      }
    }
  }, [
    buttonSectionRef.current,
    setButtonSectionHeight,
    useMediaQuery,
    isWidthLessFirstMediaStep,
  ]);

  const [isMSCIVisible, setIsMSCIVisible] = React.useState<boolean>(false);

  const isTablet = useMediaQuery(theme.breakpoints.down(1160));

  const toggleMSCIVisible = () => {
    setIsMSCIVisible(!isMSCIVisible);
  };

  const [selectedCount, setSelectedCount] = React.useState<number>(0);
  const showButton = (show: number) => {
    setSelectedCount(show);
  };

  const [compare, setCompare] = React.useState<boolean>(false);
  const compareLines = () => {
    setCompare(!compare);
  };

  const [selectedCompare, setSelectedCompare] = React.useState<Data[]>([]);
  const handleSelectedCompare = (data: Data[]) => {
    setSelectedCompare(data);
  };

  useEffect(() => {
    let arr: Data[] = [];
    selectedCompare.map((item: Data) => {
      // @ts-ignore
      let dataItem: Data | NoData = find(propEq("indexName", item.indexName))(
        data
      );

      if (!dataItem) {
        dataItem = {
          ...item,
          noData: true,
        };
      }

      return (arr = [...arr, dataItem]);
    });

    setSelectedCompare(arr);
  }, [data]);

  const headCells: any[] = [
    {
      id: "indexName",
      label: "Index",
      diffBackground: false,
    },
    {
      id: "mtd",
      label: "1 MO",
      diffBackground: false,
    },
    {
      id: "threeMtd",
      label: "3 MO",
      diffBackground: false,
    },
    {
      id: "ytd",
      label: "YTD",
      diffBackground: false,
    },
    {
      id: "oneYr",
      label: "1 YR",
      diffBackground: false,
    },
    {
      id: "threeYr",
      label: "3 YR",
      diffBackground: false,
    },
    {
      id: "fiveYr",
      label: "5 YR",
      diffBackground: false,
    },
    {
      id: "tenYr",
      label: "10 YR",
      diffBackground: false,
    },
    {
      id: "twentyYr",
      label: "20 YR",
      diffBackground: false,
    },
  ];

  return (
    <MainContainer>
      <Container>
        {loading && (
          <Loader>
            <SmallLoader />
          </Loader>
        )}

        <>
          <TitleContainer>
            <Title>Returns Summary Table</Title>
          </TitleContainer>

          <div>
            <IndicesPerformanceSummaryFilters
              compare={compare}
              compareLines={compareLines}
              selectedCount={selectedCount}
              ref={buttonSectionRef}
            />
            {data && data.length && (
              <ReturnsSummaryTable
                data={data}
                showButton={showButton}
                compareLines={compare}
                headCells={headCells}
                onSelectedCompare={handleSelectedCompare}
                selectedCompare={selectedCompare}
                buttonSection={buttonSectionHeight}
              />
            )}
          </div>

          <UnderContentInfo>
            <MSCIInfo
              isMSCIVisible={isMSCIVisible}
              underTable
              onChange={toggleMSCIVisible}
            />
            <LogoWrapper>
              <BottomMfruLogo
                src={mfruLogoSource}
                alt="logo"
                onClick={() => {
                  window.open(applicationConfig.main_page, "_blank");
                }}
              />
              <BottomLogo
                src={logoBlueSource}
                alt="logo"
                onClick={() => {
                  window.open(applicationConfig.assetcamp, "_blank");
                }}
              />
            </LogoWrapper>
          </UnderContentInfo>
        </>
      </Container>
      {isTablet && <NotifyPopUpTable />}
    </MainContainer>
  );
};

export default IndicesSummary;
