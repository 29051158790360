import React from "react";

import { VictoryAverageProps } from "./types";
import { numberFormatter } from "application/helpers/numberFormatter";

import { Label, Container } from "./styles";

const VictoryAverage = ({
  value,
  x = 0,
  y = 0,
  isPercent,
}: VictoryAverageProps) => {
  const getValue = () => {
    if (isPercent) {
      return `${numberFormatter(value)}%`;
    } else {
      return numberFormatter(value);
    }
  };

  return (
    <g style={{ pointerEvents: "none" }}>
      <foreignObject
        x={x}
        y={y - 7.5}
        width="67"
        height="15"
        style={{ overflow: "visible" }}
      >
        <Container>
          <Label>Average {getValue()}</Label>
        </Container>
      </foreignObject>
    </g>
  );
};

export default VictoryAverage;
