import { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { filter, propEq } from "ramda";

import type { Data } from "application/components/StickyHeaderTable/types";
import { historicalSelector } from "./store/slice";
import HistoricalStockActions from "./store/actions";
import { useAppDispatch, useAppSelector } from "application/store";
import { applicationConfig } from "application/config/applicationConfig";
import StickyHeaderTable from "application/components/StickyHeaderTable";
import { historicalPageLoading } from "common/responseHandlerService/slice";
import { headCells } from "application/constants/historicalStock/historicalStock";

import MSCIInfo from "application/components/MSCIInfo";
import SmallLoader from "application/components/SmallLoader";
import MainContainer from "application/components/MainContainer";
import NotifyPopUpTable from "application/components/NotifyPopUpTable";
import TableDateActions from "application/components/TableDateActions";

import {
  Title,
  Loader,
  SubTitle,
  Container,
  BottomLogo,
  TitleContainer,
  UnderContentInfo,
  LogoWrapper,
  BottomMfruLogo,
} from "./styles";
import logoBlueSource from "application/assets/images/logo-blue.svg";
import mfruLogoSource from "application/assets/images/mfru-logo.svg";
import ReportLastUpdateAction from "../home/actions/ReportLastUpdateAction";

const HistoricalStock = () => {
  const dispatch = useAppDispatch();
  const [lastDate, setLastDate] = useState<string>("");
  const [height, setHeight] = useState<number>(102);

  const loading = useAppSelector(historicalPageLoading);
  const { data, lastUpdatedDate } = useAppSelector(historicalSelector);

  const [compare, setCompare] = React.useState<boolean>(false);
  const [selectedCount, setSelectedCount] = React.useState<number>(0);
  const [isMSCIVisible, setIsMSCIVisible] = React.useState<boolean>(false);
  const [selectedCompare, setSelectedCompare] = React.useState<Data[]>([]);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1160));
  const isTableHeight = useMediaQuery(theme.breakpoints.down(1024));
  const isMobileHeight = useMediaQuery(theme.breakpoints.down(940));

  const showButton = (show: number) => {
    setSelectedCount(show);
  };

  const compareLines = () => {
    setCompare(!compare);
  };

  const toggleMSCIVisible = () => {
    setIsMSCIVisible(!isMSCIVisible);
  };

  const handleSelectedCompare = (data: Data[]) => {
    setSelectedCompare(data);
  };

  const handleDateCalculation = (date: Moment) => {
    const yearMonth = date.format("YYYY-MM"); // 2022-11
    const { getHistoricalStock, getHistoricalStockDateCalculation } =
      HistoricalStockActions;
    Promise.all([
      dispatch(getHistoricalStock(yearMonth)),
      dispatch(getHistoricalStockDateCalculation(yearMonth)),
    ]);
  };

  useEffect(() => {
    if (isMobileHeight) {
      setHeight(75)
    } else if (isTableHeight) {
      setHeight(85)
    }

    if (!data) {
      Promise.all([
        dispatch(HistoricalStockActions.getHistoricalStock()),
        dispatch(HistoricalStockActions.getHistoricalStockLastUpdatedDate()),
        ReportLastUpdateAction.getLastUpdateDate().then((data) => {
          if (data) {
            setLastDate(data.lastUpdatedDate);
          }
        })
      ]);
    } else {
      Promise.all([
        ReportLastUpdateAction.getLastUpdateDate().then((data) => {
          if (data) {
            setLastDate(data.lastUpdatedDate);
          }
        })
      ]);
    }
  }, []);

  useEffect(() => {
    if (selectedCompare.length && data) {
      let newArr:Data[] = [];
      selectedCompare.forEach((item) => {
        const filtered = filter(propEq('indexName', item.indexName))(data.historicalStock);
        newArr.push(filtered[0]);
      });

      setSelectedCompare(newArr);
    }
  }, [data]);

  return (
    <MainContainer>
      <Container>
        {loading && (
          <Loader>
            <SmallLoader />
          </Loader>
        )}
        {data && lastUpdatedDate && lastDate.length ? (
          <>
            <TitleContainer>
              <Title>10-Year Performance Attribution</Title>
              <SubTitle>
                {compare ? "You can select historical period for custom calculations"
                  : "You can select historical period for custom calculations and compare 2 or more indexes"
                }
              </SubTitle>
            </TitleContainer>

            <div>
              <TableDateActions
                compare={compare}
                compareLines={compareLines}
                selectedCount={selectedCount}
                lastUpdatedDate={lastUpdatedDate}
                lastDate={lastDate}
                handleDateCalculation={handleDateCalculation}
              />

              <StickyHeaderTable
                historicalStock={data.historicalStock}
                dataUnitsOfMeasurement={data.dataUnitsOfMeasurement}
                showButton={showButton}
                compareLines={compare}
                headCells={headCells}
                onSelectedCompare={handleSelectedCompare}
                selectedCompare={selectedCompare}
                buttonSection={height}
              />
            </div>

            <UnderContentInfo>
              <MSCIInfo
                isMSCIVisible={isMSCIVisible}
                underTable
                onChange={toggleMSCIVisible}
              />
              <LogoWrapper>
                <BottomMfruLogo
                  src={mfruLogoSource}
                  alt="logo"
                  onClick={() => {
                    window.open(applicationConfig.main_page, "_blank");
                  }}
                />
                <BottomLogo
                  src={logoBlueSource}
                  alt="logo"
                  onClick={() => {
                    window.open(applicationConfig.assetcamp, "_blank");
                  }}
                />
              </LogoWrapper>

            </UnderContentInfo>
          </>
        ) : false}
      </Container>
      {isTablet && <NotifyPopUpTable />}
    </MainContainer>
  );
};

export default HistoricalStock;
