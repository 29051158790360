import styled from "styled-components";
import { colors } from "../../application/styles/colors";

export interface ButtonStyledProps {
  isActive: boolean;
}

export interface StockSectionDividerProps {
  width: string;
  cardsCount: number;
}

const mediaStep_1 = 1160;
const mediaStep_2 = 1023;
const mediaStep_3 = 647;

export const ContainerStyled = styled.div`
  .global-footer {
    margin-top: 0;
  }
`;

export const HowToTitle = styled.span`
  font-family: "Times";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: ${colors.black};
  margin-bottom: 12px;

  @media only screen and (max-width: ${mediaStep_2}px) {
    font-size: 20px;
    line-height: 24px;
    margin-left: 12px;
    margin-bottom: 8px;
  }

  @media only screen and (max-width: ${mediaStep_3}px) {
    margin-right: 20px;
  }
`;

export const HowToText = styled.span`
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.font_grey};

  @media only screen and (max-width: ${mediaStep_2}px) {
    margin-left: 12px;
  }

  @media only screen and (max-width: ${mediaStep_3}px) {
    margin-right: 20px;
  }
`;

export const HowToCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff9ef;
  box-shadow: 0 8px 22px -6px rgba(24, 39, 75, 0.12),
    0 14px 64px -4px rgba(24, 39, 75, 0.12);
  margin-top: 36px;
  padding: 40px;

  @media only screen and (max-width: ${mediaStep_2}px) {
    justify-content: space-between;
    margin: 16px;
    padding: 24px;
  }

  @media only screen and (max-width: ${mediaStep_3}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const HowToCardText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;

  @media only screen and (max-width: ${mediaStep_2}px) {
    margin-left: 0;
  }
`;

export const HowToLogo = styled.img`
  width: 60px;
  height: 60px;

  @media only screen and (max-width: ${mediaStep_3}px) {
    width: 40px;
    height: 40px;
  }
`;

export const HowToButton = styled.div`
  width: 160px;
  margin-left: 72px;

  @media only screen and (max-width: ${mediaStep_3}px) {
    width: 100%;
    margin-left: unset;
    margin-top: 12px;
  }
`;

export const TabsSectionStyled = styled("div")`
  display: flex;
  align-items: center;
  margin-top: 60px;

  @media only screen and (max-width: ${mediaStep_2}px) {
    margin: 0 16px;
  }

  > button {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const ButtonStyled = styled("button")<ButtonStyledProps>`
  display: flex;
  align-items: center;
  position: relative;
  height: 44px;
  bottom: -1px;
  padding: 0 8px;
  background: unset;
  border: 2px solid transparent;
  border-bottom: ${({ isActive }) =>
    isActive ? `2px solid ${colors.ochre}` : "2px solid transparent"};
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    path {
      fill: ${({ isActive }) => (isActive ? colors.ochre : colors.black)};
      &:hover {
        fill: ${({ isActive }) => (isActive ? colors.ochre : colors.black)};
      }
    }
  }

  p {
    color: ${({ isActive }) => (isActive ? colors.ochre : colors.black)};
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    font-family: "Helvetica";
    text-transform: uppercase;
    text-align: center;

    @media only screen and (max-width: ${mediaStep_3}px) {
      font-size: 14px;
    }
  }
`;

export const StockIndexesStyled = styled.div`
  position: relative;
  display: flex;
  width: fit-content;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  margin-top: 40px;

  @media only screen and (max-width: ${mediaStep_2}px) {
    width: unset;
    margin: 32px 16px 0 16px;
  }

  @media only screen and (max-width: ${mediaStep_3}px) {
    margin: 24px 16px 0 16px;
  }
`;

export const StockSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  @media only screen and (max-width: ${mediaStep_3}px) {
    gap: 16px;
  }
`;

export const StockSectionTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  p {
    align-self: stretch;
    color: ${colors.blue_default};
    font-family: "Times";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;

    @media only screen and (max-width: ${mediaStep_2}px) {
      font-size: 20px;
      line-height: 24px;
    }
  }
`;

export const StockSectionTitleDivider = styled.div<StockSectionDividerProps>`
  height: 1px;
  align-self: stretch;
  background: ${colors.brown_default};
  width: ${({ width }) => `${width}`};

  @media only screen and (max-width: ${mediaStep_1}px) {
    width: ${({ cardsCount }) =>
      `${cardsCount === 1 ? 32 : cardsCount === 2 ? 66 : 100}%`};
  }

  @media only screen and (max-width: ${mediaStep_2}px) {
    width: ${({ cardsCount }) => `${cardsCount === 1 ? 49 : 100}%`};
  }

  @media only screen and (max-width: ${mediaStep_3}px) {
    width: 100%;
  }
`;

export const Cards = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 36px;
  align-self: stretch;

  @media only screen and (max-width: ${mediaStep_1}px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    grid-auto-rows: minmax(100px, auto);
  }

  @media only screen and (max-width: ${mediaStep_2}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: ${mediaStep_3}px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 12px;
  }
`;

export const CardStyled = styled.div`
  display: flex;
  padding: 32px 24px;
  align-items: center;
  gap: 12px;
  background: ${colors.white};
  box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12);
  cursor: pointer;

  @media only screen and (max-width: ${mediaStep_2}px) {
    padding: 28px 24px;
  }

  @media only screen and (max-width: ${mediaStep_3}px) {
    padding: 20px 16px;
  }
`;

export const CardIcon = styled.img`
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
`;

export const CardDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 272px;
  height: 56px;
  color: ${colors.black};
  font-family: "Times";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;

  @media only screen and (max-width: ${mediaStep_1}px) {
    width: fit-content;
  }

  @media only screen and (max-width: ${mediaStep_2}px) {
    font-size: 18px;
  }
`;

export const BondMarketStyled = styled.div`
  display: flex;
  width: 1140px;
  padding-top: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  @media only screen and (max-width: ${mediaStep_1}px) {
    width: 100%;
  }
`;

export const ChartImage = styled.img`
  display: flex;
  width: 424px;
  padding: 8px 7px 0px 7px;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: ${mediaStep_3}px) {
    width: 270px;
  }
`;

export const BondMarketText = styled.p`
  color: ${colors.black};
  font-family: Times;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
`;
