export enum PathNames {
  // Auth
  login = "/",
  passwordRecovery = "/password-recovery",
  passwordRecoveryReset = "/password-recovery-reset",
  changePassword = "/change-password",
  registration = "/registration",
  registrationBilling = "/registration-billing",
  registrationSubscriptionPlan = "/registration-subscription-plan",
  registrationPayment = "/registration-payment",
  registrationTerms = "/registration-terms",
  // Update subscription
  updateSubscriptionPlan = "/update-subscription-plan",
  updateSubscriptionPayment = "/update-subscription-payment",
  // App
  home = "/",
  bonds = "/bonds",
  valuationReports = "/stock-market-valuation",
  valuationSummary = "/stock-market-valuation-summary",
  // Global
  error = "/error",
  //Expect stock market returns
  expectStock = "/expect-stock",
  // Historical Stock
  historicalStock = "/historical-stock",
  //Performance Comparison Tool
  indicesComparison = "/indices-comparison",
  //Returns Summary Table
  indicesSummary = "/index-performance-summary",
}
