import styled from "styled-components";

import { AuthTitle } from "../styles";
import { colors } from "application/styles/colors";

export const SignUpContent = styled.div`
  width: calc(100% - 32px);
  max-width: 614px;
  padding: 0 16px;

  @media only screen and (max-width: 1023px) {
    width: 674px;
  }

  @media only screen and (max-width: 674px) {
    width: calc(100% - 32px);
    padding: 0 16px;
  }
`;

export const SignUpTitle = styled(AuthTitle)`
  margin-bottom: 24px;
  position: relative;
  z-index: 10;

  @media only screen and (max-width: 674px) {
    display: none;
  }
`;

export const SignUpSubTitle = styled.h3`
  margin-bottom: 32px;
  /* Font style */
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${colors.font_grey};

  @media only screen and (max-width: 674px) {
    display: none;
  }
`;

export const TermsContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 25px 0 16px 0;

  & > div:first-child {
    margin-right: 8px;
  }
`;

export const TermsAction = styled.span`
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.ochre};
  cursor: pointer;
`;

export const RegistrationBtn = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  & > button {
    max-width: 287px;
  }
`;

export const AgreeContainer = styled.div`
  display: flex;
  margin-top: 56px;

  & > div:first-child {
    margin-right: 8px;
  }

  @media only screen and (max-width: 674px) {
    margin-top: 0;
    margin-bottom: 24px;
  }
`;

export const PasswordTip = styled.ul`
  list-style: inside;
  padding-left: 5px;
  li span {
    left: -5px;
    position: relative;
  }
`;

export const MobileConfirmButton = styled.div`
  width: 642px;
  margin: 0 auto 24px;

  @media only screen and (max-width: 674px) {
    width: 100%;
  }
`;