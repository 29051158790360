import React from "react";
import moment from "moment";
import { DateDot, UpdateDate } from "./styles";

const LastUpdateDate = ({ date }: { date: string }) => {  
  return (
    <UpdateDate>
      Data as of {moment.utc(date).format("MMMM D, YYYY")}
      <DateDot>•</DateDot>
      Valued in U.S. Dollars
    </UpdateDate>
  );
};

export default LastUpdateDate;
