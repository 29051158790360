import styled from "styled-components";

import { colors } from "application/styles/colors";

const corner_mask = `
  -webkit-clip-path: polygon(0 7px,7px 0,calc(100% - 7px) 0,100% 7px,100% calc(100% - 7px),calc(100% - 7px) 100%,7px 100%,0 calc(100% - 7px));
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  box-sizing: border-box;
  background: ${colors.brown_hover};
`;

export const Label = styled.p`
  /* Font style */
  font-size: 7px;
  font-weight: 700;
  line-height: 20px;
  font-style: normal;
  color: ${colors.white};
  font-family: "Helvetica";
`;
