import styled from "styled-components";
import { VictoryStyleInterface } from "victory";

import { colors } from "application/styles/colors";
import { ReactComponent as InfoIconSvg } from "application/assets/images/icons/info-icon.svg";

export const ChartWrapper = styled.div`
  position: relative;
  margin-bottom: 28px;
  border: 1px solid ${colors.stroke_grey};

  @media (max-width: 1023px) {
    border: none;
    border-bottom: 1px solid ${colors.stroke_grey};
    margin-bottom: 16px;
  }
`;

export const Loader = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
`;

export const ChartText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;
  font-family: "Helvetica";
  color: ${colors.font_grey};

  @media (max-width: 1000px) {
    font-size: 14px;
  }
`;

export const ChartSmallText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;
  font-family: "Helvetica";
  color: ${colors.font_grey};
`;

export const EarningContainer = styled.div`
  display: flex;
  align-items: center;

  &::before {
    content: "";
    min-width: 12px;
    height: 12px;
    margin-right: 8px;
    border-radius: 50%;
    background-color: ${colors.blue_400};
  }
`;

export const AverageContainer = styled.div`
  display: flex;
  align-items: center;

  &::before {
    content: "";
    width: 12px;
    height: 12px;
    margin-right: 8px;
    border-radius: 50%;
    background-color: ${colors.brown_hover};
  }
`;

export const SpecificMetricContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SpecificMetricSign = styled.div`
  width: 12px;
  height: 12px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${colors.brown_hover};
`;

export const TipsContainer = styled.div`
  display: flex;
  & > div:first-child {
    margin-right: 24px;
  }

  @media (max-width: 1023px) {
    margin-top: 12px;
  }
`;

export const InfoIcon = styled(InfoIconSvg)`
  margin-left: 4px;
  stroke: ${colors.font_grey};
`;

export const NoDataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 400px;
`;

export const NoDataText = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;
  font-family: "Helvetica";
  color: ${colors.font_grey};
`;

export const BottomContainer = styled.div`
  padding: 0 36px 36px 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1023px) {
    flex-direction: column-reverse;
    padding-bottom: 18px;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1023px) {
    width: 100%;
    justify-content: center;
  }
`;

export const LogosWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  @media (max-width: 647px) {
    display: none;
  }
`;

export const BottomMfruLogo = styled.img`
  width: 95px;
  height: 38px;
  cursor: pointer;
  margin-right: 20px;

  @media (max-width: 1024px) {
    width: 75px;
    height: 30px;
    margin-left: 16px;
  }

  @media (max-width: 647px) {
    margin-left: 8px;
  }
`;

export const BottomLogo = styled.img`
  width: 92px;
  height: 32px;
  cursor: pointer;

  @media (max-width: 1024px) {
    width: 74px;
    height: 26px;
    margin-left: 16px;
  }

  @media (max-width: 647px) {
    margin-left: 8px;
  }
`;

const tickLabels = {
  fontSize: 8,
  fontWeight: 300,
  color: colors.font_grey,
  fontFamily: "Helvetica",
  fill: colors.font_grey,
};

export const chartStyles = {
  scatterStyle: {
    data: {
      strokeWidth: 5,
      fill: colors.blue_700,
      stroke: "rgba(30, 136, 229, 0.2)",
    },
  } as VictoryStyleInterface,
  movingAvgScatterStyle: {
    data: {
      strokeWidth: 5,
      fill: colors.brown_hover,
      stroke: "rgba(30, 136, 229, 0.2)",
    },
  } as VictoryStyleInterface,
  axisStyle: {
    tickLabels: { ...tickLabels, padding: 5 },
    axis: {
      stroke: colors.font_grey,
      strokeWidth: 2,
    },
    ticks: { stroke: colors.font_grey, size: 4 },
  },
  yLeftAxisStyle: {
    axis: {
      stroke: "",
    },
    grid: {
      stroke: colors.grey_300,
    },
    tickLabels,
  },
  yRightAxisStyle: {
    axis: {
      stroke: "",
    },
    grid: {
      stroke: colors.blue_default,
    },
    tickLabels: {
      fontSize: 8.5,
      fontWeight: 400,
      fontFamily: "Helvetica",
      fill: colors.blue_default,
      color: colors.blue_default,
    },
  },
};

export const MetricValueRange = styled.div`
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 20px;
  color: ${colors.font_grey};

  @media (max-width: 647px) {
    font-size: 14px;
  }
`;
